import * as React from "react";

import TextareaAutosize from "react-textarea-autosize";
import deepEqual from "fast-deep-equal";

import { createTheme, NextUIProvider, styled, Switch, Button, Text, Spacer } from "@nextui-org/react";

type Tonne = "gelb" | "braun" | "gruen" | "schwarz";
type Status = "draussen" | "drinnen" | "keine";

const yellow500 = "#fdfd96";
const green500 = "#C1E1C1";
const brown500 = "#836953";

const palette = ["#809bce", "#95b8d1", "#b8e0d2", "#d6eadf", "#eac4d5"];

const defaultMuell = {
  gelb: "drinnen",
  braun: "drinnen",
  gruen: "drinnen",
  schwarz: "drinnen",
} as const;

const haus = (label: string) => ({
  label,
  wasser: {
    checked: false,
    lastChecked: null,
  },
  runde: {
    checked: false,
    lastChecked: null,
  },
  muell: defaultMuell,
  notiz: "",
});

type HistoricToggle = {
  checked: boolean;
  lastChecked: number | null;
};

type State = {
  label: string;
  paused?: boolean;
  wasser: HistoricToggle;
  runde: HistoricToggle;
  muell: {
    gelb: Status;
    gruen: Status;
    braun: Status;
    schwarz: Status;
  };
  notiz: string;
};

function App() {
  const [state, setState] = usePersistedState<Array<State>>("state", [
    haus("Hantrup"),
    haus("Adler"),
    haus("Knocks"),
    haus("Meckelburger"),
    haus("Blume"),
    haus("Ruths"),
    haus("Kutzner"),
    haus("Cremer"),
    haus("Spethmann"),
    haus("Duering"),
    haus("Euler"),
    haus("Seemann"),
    haus("Elisabeth"),
  ]);

  const toggleOut = (i: number) => (tonne: Tonne) => {
    const muell = state[i].muell;
    update(
      i,
      "muell"
    )({
      ...muell,
      [tonne]: muell[tonne] === "draussen" ? "drinnen" : "draussen",
    });
  };

  React.useEffect(() => {
    const mapState = (s: State) => {
      return {
        ...s,
        ...(s.runde.lastChecked && Date.now() - s.runde.lastChecked > 1000 * 60 * 60 * 24
          ? { runde: { ...s.runde, checked: false } }
          : null),
        ...(s.wasser.lastChecked && Date.now() - s.wasser.lastChecked > 1000 * 60 * 60 * 24
          ? { wasser: { ...s.wasser, checked: false } }
          : null),
      };
    };
    const i = setInterval(() => {
      setState((state: State[]): State[] => state.map(mapState));
    }, 1000);
    return () => {
      clearInterval(i);
    };
  }, [setState]);

  const update = (i: number, prop: keyof State) => (value: unknown) => {
    setState(state.map((r, j) => (j !== i ? r : { ...r, [prop]: value })));
  };
  const updateHist = (i: number, prop: "wasser" | "runde") => {
    const value = { checked: !state[i][prop].checked, lastChecked: Date.now() };
    setState(state.map((r, j) => (j !== i ? r : { ...r, [prop]: value })));
  };

  return (
    <NextUIProvider theme={theme}>
      <Container>
        <Config style={{ display: "none" }}>
          <Text
            h1
            css={{
              textAlign: "center",
              textGradient: "45deg, $blue600 -20%, $pink600 50%",
            }}
          >
            Aufgaben
          </Text>
          <CloseBtn>✖</CloseBtn>
          <div>
            <WeekDaySelector>
              <Text h4 css={{ margin: 0 }}>
                Wasser
              </Text>
              <DayBtn label="Mo" selected={false} />
              <DayBtn label="Di" selected />
              <DayBtn label="Mi" selected />
              <DayBtn label="Do" selected />
              <DayBtn label="Fr" selected />
              <DayBtn label="Sa" selected />
              <DayBtn label="So" selected />
            </WeekDaySelector>
          </div>
          <Spacer y={1} />
          <div>
            <WeekDaySelector>
              <Text h4 css={{ margin: 0 }}>
                Garage
              </Text>
              <DayBtn label="Mo" selected={false} />
              <DayBtn label="Di" selected />
              <DayBtn label="Mi" selected />
              <DayBtn label="Do" selected />
              <DayBtn label="Fr" selected />
              <DayBtn label="Sa" selected />
              <DayBtn label="So" selected />
            </WeekDaySelector>
          </div>
          <Text h4 css={{ margin: 0 }}>
            Mülltonnen
          </Text>
          {["Braun", "Gelb", "Blau", "Grau"].map((d) => {
            return (
              <WeekDaySelector key={d}>
                <Button auto>{d}</Button>

                <DayBtn label="Mo" selected={false} />
                <DayBtn label="Di" selected />
                <DayBtn label="Mi" selected />
                <DayBtn label="Do" selected />
                <DayBtn label="Fr" selected />
                <DayBtn label="Sa" selected />
                <DayBtn label="So" selected />
              </WeekDaySelector>
            );
          })}
        </Config>
        {state.map((h, i) => {
          return (
            <div style={{ marginBottom: 20 }} key={i}>
              <Badge css={{ backgroundColor: palette[i % palette.length] }}>
                <div onClick={() => update(i, "paused")(!state[i].paused)}>
                  {h.label}
                  {h.paused ? " (belegt)" : ""}
                </div>
              </Badge>
              <Row>
                <Cell>
                  <span style={{ marginRight: 4 }}>
                    {h.wasser.lastChecked ? `${new Date(h.wasser.lastChecked).getDate()}.` : ""}
                  </span>
                  <Switch checked={h.wasser.checked} icon="💧" onChange={() => updateHist(i, "wasser")} />
                </Cell>
                <Cell>
                  <span style={{ marginRight: 4 }}>
                    {h.runde.lastChecked ? `${new Date(h.runde.lastChecked).getDate()}.` : ""}
                  </span>
                  <Switch color="success" onChange={() => updateHist(i, "runde")} icon="🚶" checked={h.runde.checked} />
                </Cell>
                <Cell>
                  <Muell color="gelb" onClick={toggleOut(i)} status={h.muell.gelb} />
                  <Muell color="gruen" onClick={toggleOut(i)} status={h.muell.gruen} />
                  <Muell color="braun" onClick={toggleOut(i)} status={h.muell.braun} />
                  <Muell color="schwarz" onClick={toggleOut(i)} status={h.muell.schwarz} />
                </Cell>
              </Row>
              <Notiz onChange={update(i, "notiz")} onClear={() => update(i, "notiz")("")} value={h.notiz} />
            </div>
          );
        })}
      </Container>
    </NextUIProvider>
  );
}
const Row = styled("div", {
  dflex: "center",
  gap: 8,
  padding: 12,
});

const Badge = styled("div", {
  position: "relative",
  borderRadius: 9999,
  textAlign: "center",
  padding: "4px 8px",
  fontSize: "20px",
  lineHeight: "32px",
  width: "100%",
});

const Muell = styled(
  (p: { color: Tonne; onClick: (t: Tonne) => void; status: Status }) => {
    const { status, ...rest } = p;
    if (status === "keine") return null;
    const color = {
      gruen: green500,
      braun: brown500,
      schwarz: "black",
      gelb: yellow500,
    }[p.color];
    return (
      <div
        {...rest}
        onClick={() => p.onClick(p.color)}
        style={{
          border: `2px solid black`,
          borderRadius: 999,
          color: [yellow500, green500].includes(color) ? "black" : "white",
          textAlign: "center",
          background: color,
        }}
      >
        {status === "draussen" ? "→" : ""}
      </div>
    );
  },
  {
    cursor: "pointer",
    position: "relative",
    transition: "0.5s all",
    marginRight: 2,
    width: 28,
    height: 28,
  }
);

const Cell = styled("div", {
  display: "flex",
  alignItems: "center",
});

const Container = styled("div", {
  backgroundColor: "#eeeeff",
  padding: "12px 20px",
  minHeight: "100vh",
  overflow: "scroll",
});

const Notiz = styled(
  (p: { value: string; onChange: (e: string) => void; onClear: () => void }) => {
    const { onClear, ...textareaProps } = p;
    const ref = React.useRef<HTMLTextAreaElement>(null);
    return (
      <div style={{ display: "flex", position: "relative" }}>
        <TextareaAutosize
          {...textareaProps}
          ref={ref}
          onChange={(e) => p.onChange(e.target.value)}
          value={p.value}
          rows={1}
        />
        {p.value.length ? (
          <div
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              border: "1px solid black",
              borderRadius: 9999,
              width: 24,
              height: 24,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "12px",
              cursor: "pointer",
            }}
            onClick={() => {
              p.onClear();

              ref.current?.focus();
            }}
          >
            <div>✕</div>
          </div>
        ) : null}
      </div>
    );
  },
  {
    borderRadius: 20,
    border: 0,
    padding: "8px 40px 8px 16px",
    width: "100%",
  }
);

export default React.memo(App);

const theme = createTheme({
  type: "light",
  theme: {
    colors: {},
  },
});

let t = 0;

const login = async () => {
  await fetch("/api/login", {
    headers: { "Content-Type": "application/json" },
    method: "POST",
    body: JSON.stringify({ user: prompt("Benutzername"), password: prompt("Passwort") }),
  });
};

const getData = async (): Promise<unknown> => {
  return fetch("/api/get").then(async (r) => {
    if (r.ok) return r.json();
    await login();
    return getData();
  });
};

function usePersistedState<State>(key: string, fallback: State): [State, React.Dispatch<React.SetStateAction<State>>] {
  const [x, _setX] = React.useState<State>(() => {
    function sync() {
      getData().then((data) => {
        if (Array.isArray(data)) _setX(data as any);
        setTimeout(sync, 10000);
      });
    }
    sync();
    const data = localStorage.getItem(key);

    return data ? JSON.parse(data) : fallback;
  });
  const setX = (s: State | ((x: State) => State)) => {
    // @ts-ignore
    const newState = typeof s === "function" ? s(x) : s;

    if (!deepEqual(newState, x)) {
      _setX(newState);
      clearTimeout(t);
      t = window.setTimeout(() => {
        localStorage.setItem(key, JSON.stringify(newState));
        fetch("/api/set", {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify(newState),
        });
      }, 500);
    }
  };

  return [x, setX];
}

const Config = styled("div", {
  position: "fixed",
  top: 8,
  bottom: 8,
  left: 8,
  right: 8,
  border: `1px solid #6456b7`,
  background: "white",
  zIndex: 1,
  borderRadius: 16,
});

const CloseBtn = styled(
  (props: {}) => (
    <div {...props}>
      <span>✖</span>
    </div>
  ),
  {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 8,
    right: 8,
    cursor: "pointer",
    width: 24,
    height: 24,
    borderRadius: 9999,
    border: "1px solid black",
  }
);

const DayBtn = styled((p: { label: string; selected: boolean }) => {
  const { label, selected, ...divProps } = p;
  return (
    <Button
      color="secondary"
      css={{ height: 40, padding: 0, width: 40 }}
      rounded
      ghost={selected}
      auto
      children={label}
      {...divProps}
    />
  );
});

const WeekDaySelector = styled("div", {
  d: "flex",
  alignItems: "center",
  gap: "$4",
});
